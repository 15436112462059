var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-card',{staticClass:"pa-3",attrs:{"color":_vm.document.status === 3 ? 'error lighten-3' : ''}},[_c('v-card-title',[_c('div',{staticClass:"text-capitalize text-h4"},[_vm._v(" "+_vm._s(_vm.$t(`document.types.${_vm.document.name}`))+" ")]),_c('br'),_c('div',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t(`document.statusKey.${_vm.document.status}`))+" ")])]),(_vm.documentCreationMessage.message)?_c('v-alert',{attrs:{"text":"","type":_vm.documentCreationMessage.type}},[_vm._v(" "+_vm._s(_vm.documentCreationMessage.message)+" ")]):_vm._e(),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('validation-provider',{attrs:{"inmediate":"","name":_vm.document.name,"rules":"required|size:2000"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_vm._l((_vm.docURLs),function(file,i){return _c('v-card-text',{key:file},[_c('show-file',{attrs:{"url":file},on:{"removeFile":function($event){file = null}}}),(_vm.document.status === 3)?_c('v-file-input',{key:`${_vm.document.name}-file-input`,ref:`fileInput-${i}`,refInFor:true,attrs:{"name":`fileInput-${_vm.document.name}-${i}`,"data-vv-as":`file-${i - 1}`,"accept":"image/png, image/jpeg, application/pdf","placeholder":`${_vm.$t('actions.load')} ${_vm.$t(
              'document.types.' + _vm.document.name
            )} ${i + 1}`,"error":errors.length > 0,"error-messages":errors[0],"counter":"","chips":"","show-size":"","truncate-length":"15","required":"","small-chips":"","clearable":""},on:{"click":function($event){return _vm.setDocumentCreationMessage({})},"change":function($event){_vm.docURLs[i] = _vm.getURL(_vm.files[i])}},model:{value:(_vm.files[i]),callback:function ($$v) {_vm.$set(_vm.files, i, $$v)},expression:"files[i]"}},[_c('v-icon',{attrs:{"slot":"append","color":"red"},on:{"click":function($event){delete _vm.docURLs[i - 1]((_vm.inputsArray[i - 1] = false))(
                  _vm.filesCounter - 1 < 1
                    ? (_vm.filesCounter = 1)
                    : (_vm.filesCounter -= 1)
                )}},slot:"append"},[_vm._v(" mdi-minus ")])],1):_vm._e()],1)}),(_vm.document.status === 3)?_c('v-card-actions',[(_vm.documentCreationMessage.type !== 'success')?_c('v-btn',{staticClass:"my-2",attrs:{"color":"success","disabled":invalid},on:{"click":function($event){return _vm.validate()}}},[_vm._v(" "+_vm._s(_vm.$t('actions.save'))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-cloud-upload")])],1):_vm._e()],1):_vm._e()]}}],null,true)})]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }